import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Explore Unlimited
			</title>
			<meta name={"description"} content={"ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА."} />
			<meta property={"og:title"} content={"Explore Unlimited"} />
			<meta property={"og:description"} content={"ВАШ ШЛЯХ ДО НЕЗАБУТНІХ ПРИГОД І НЕЗАБУТНІХ ВРАЖЕНЬ ВІД ОРЕНДИ КВАДРОЦИКЛА."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-5.jpg?v=2024-05-07T12:36:57.721Z"} />
		</Helmet>
		<Components.Header12 />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Про нас
				</Text>
			</Box>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12:36:57.672Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-5.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--grey"
					md-text-align="left"
				>
					В Explore Unlimited кожна поїздка - це більше, ніж просто мандрівка, - це подорож через дику красу та незвідані території. Наша місія - надати доступну пригоду кожному, хто прагне вирватися з буденності. Кожен вигин стежки і поворот шляху таїть у собі історію, яка чекає, щоб ви її відкрили.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12:36:57.672Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-1.jpg?v=2024-05-07T12%3A36%3A57.672Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Explore Unlimited народився з пристрасті до відпочинку на свіжому повітрі та бажання зробити пригоди доступнішими для всіх. Завдяки багаторічному досвіду ми вдосконалили наші послуги, щоб створити неперевершений досвід оренди квадроциклів, що обіцяє не лише гострі відчуття та хвилювання, а й безпеку та комфорт.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12:36:57.697Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-3.jpg?v=2024-05-07T12%3A36%3A57.697Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Наш винятковий парк
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					Наша гордість - наш парк сучасних квадроциклів, кожен з яких ретельно обслуговується, щоб забезпечити продуктивність і надійність. Наші автомобілі здатні впоратися з будь-якими труднощами, чи то пересічена місцевість, чи то пологі схили, що дає змогу вам зосередитися на отриманні задоволення від поїздки та створенні спогадів.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12:36:57.694Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12%3A36%3A57.694Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12:36:57.688Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						object-position="left"
						srcSet="https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d7741d712a00233396a9/images/2-3.jpg?v=2024-05-07T12%3A36%3A57.688Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Чому варто обрати Explore Unlimited?
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					<Strong>
						Неперевершена якість:
					</Strong>
					{" "}кожен квадроцикл являє собою модель вищого рівня, що забезпечує плавну і потужну їзду.{" "}
					<br />
					<br />
					<Strong>
						Індивідуальний підхід:
					</Strong>
					{" "}ми вважаємо, що кожен шукач пригод унікальний, і забезпечуємо індивідуальний підхід, щоб зробити ваш досвід особливим.{"\n"}
					<br />
					<br />
					<Strong>
						Прагнення до досконалості
					</Strong>
					: від протоколів безпеки до обслуговування клієнтів, досконалість є нашим стандартом.{"\n"}
					<br />
					<br />
					<Strong>
						Незабутні враження:
					</Strong>
					{" "}ми не просто пропонуємо поїздки - ми пропонуємо пригоди, які залишаться на все життя.
				</Text>
			</Box>
		</Section>
		<Components.Contacts>
			<Override slot="text2" />
			<Override slot="text3" />
		</Components.Contacts>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});